.page-wrapper {
  background: rgb(33, 33, 33);
  background: linear-gradient(45deg, rgb(33, 33, 33) 0%, rgb(71, 71, 71) 30%, rgb(124, 124, 124) 60%, rgb(255, 255, 255) 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100vw;
  min-height: 100vh;
}
.page-wrapper .projects-main {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.page-wrapper .projects-main .projects-title {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  font-size: 38px;
  font-weight: 700;
  background: -webkit-linear-gradient(rgb(226, 226, 226), #383838);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  gap: -20px;
}
.page-wrapper .projects-main .projects-block {
  width: 90%;
  display: flex;
  justify-content: center;
}
.page-wrapper .projects-main .projects-block .projects-wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 80%;
  gap: 50px;
  justify-content: center;
  transition: 0.5s;
  opacity: 1;
  margin: 10em 0;
}
@media (max-width: 1400px) {
  .page-wrapper .projects-main .projects-block .projects-wrapper {
    display: flex;
    flex-direction: column;
  }
}
.page-wrapper .projects-main .projects-block .projects-wrapper .project-card {
  position: relative;
  text-align: center;
  overflow: hidden;
  border-radius: 20px;
  box-shadow: -8px 10px 20px rgb(47, 47, 47);
  transition: 0.5s;
}
.page-wrapper .projects-main .projects-block .projects-wrapper .project-card img {
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  height: 100%;
  transition: 0.5s;
}
.page-wrapper .projects-main .projects-block .projects-wrapper .project-card:hover {
  transform: scale(1.1);
}
.page-wrapper .projects-main .projects-block .projects-wrapper .project-card:hover img {
  transform: scale(1.1);
}
.page-wrapper .projects-main .projects-block .projects-wrapper .project-card:hover .project-info {
  opacity: 1;
}
.page-wrapper .projects-main .projects-block .projects-wrapper .project-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  border-radius: 10px;
  opacity: 0;
  transition: opacity 0.3s ease;
}
@media (max-width: 1400px) {
  .page-wrapper .projects-main .projects-block .projects-wrapper .project-info {
    opacity: 1;
  }
}
.page-wrapper .projects-main .projects-block .projects-wrapper .project-info p {
  font-size: 1vw;
  margin: 20px 0;
}
@media (max-width: 1400px) {
  .page-wrapper .projects-main .projects-block .projects-wrapper .project-info p {
    font-size: 3vw;
  }
}
@media (max-width: 600px) {
  .page-wrapper .projects-main .projects-block .projects-wrapper .project-info p {
    font-size: 4vw;
  }
}
.page-wrapper .projects-main .projects-block .projects-wrapper .project-info h1 {
  margin: 20px 0;
}
@media (max-width: 1400px) {
  .page-wrapper .projects-main .projects-block .projects-wrapper .project-info h1 {
    font-size: 4vw;
  }
}
@media (max-width: 600px) {
  .page-wrapper .projects-main .projects-block .projects-wrapper .project-info h1 {
    font-size: 5vw;
  }
}
.page-wrapper .projects-main .projects-block .projects-wrapper .project-info .more-info {
  color: white;
  text-decoration: underline;
}
.page-wrapper .more-info-hidden {
  transition: 0.5s;
  opacity: 0;
  transform: scale(0);
}
.page-wrapper .more-info-visible {
  transform: scale(1);
  opacity: 1;
  border-radius: 20px;
  background-color: rgb(33, 33, 33);
  width: 80%;
  transition: 0.5s;
  padding: 20px;
  margin-bottom: 10em;
  color: white;
  box-shadow: -10px 10px 40px rgb(0, 0, 0), 5px -5px 80px rgba(97, 97, 97, 0.469), inset -2px 2px 20px rgba(97, 97, 97, 0.469);
}
.page-wrapper .more-info-visible .content {
  width: 100%;
  display: flex;
  justify-content: center;
}
.page-wrapper .more-info-visible .info-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 25px;
  width: 100%;
  /* .see-site{
      position: relative;
      bottom: 0;
  } */
}
.page-wrapper .more-info-visible .info-text .link {
  text-decoration: none;
  min-height: 50px;
  min-width: 2vw;
  color: white;
  font-size: 1em;
  transition: 0.5s;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  padding: 0 10px;
}
.page-wrapper .more-info-visible .info-text .link:hover {
  box-shadow: 0px 8px 20px black;
  transform: translateY(10%);
}
.page-wrapper .more-info-visible .info-text .info-grid {
  display: flex;
  justify-content: center;
  gap: 25px;
  width: 100%;
}
@media (max-width: 600px) {
  .page-wrapper .more-info-visible .info-text .info-grid {
    flex-direction: column;
    align-items: center;
  }
}
.page-wrapper .more-info-visible .info-text .info-grid > div {
  box-shadow: -4px 4px 10px black;
  border-radius: 10px;
  transition: 0.5s;
  overflow: hidden;
  background-color: rgb(33, 33, 33);
  width: 30%;
  padding: 0 5%;
}
@media (max-width: 600px) {
  .page-wrapper .more-info-visible .info-text .info-grid > div {
    width: 50%;
  }
}
.page-wrapper .more-info-visible .info-text .more-info, .page-wrapper .more-info-visible .info-text .link, .page-wrapper .more-info-visible .info-text .see-site {
  color: white;
  font-weight: 700;
  align-self: flex-start;
  transition: 0.5s;
}
.page-wrapper .more-info-visible .info-text a:hover {
  color: grey;
}
.page-wrapper .more-info-visible .info-text h3 {
  align-self: center;
  font-size: 26px;
}
@media (max-width: 1000px) {
  .page-wrapper .more-info-visible .info-text h3 {
    font-size: 2.5vw;
  }
}
.page-wrapper .more-info-visible .info-text p {
  font-size: 20px;
}
@media (max-width: 1000px) {
  .page-wrapper .more-info-visible .info-text p {
    font-size: 2vw;
  }
}
@media (max-width: 600px) {
  .page-wrapper .more-info-visible .info-text p {
    font-size: 3vw;
  }
}
.page-wrapper .more-info-visible .info-text .developped-skills {
  display: flex;
  flex-direction: row;
  margin-top: 50px;
  gap: 50px;
}
@media (max-width: 600px) {
  .page-wrapper .more-info-visible .info-text .developped-skills {
    flex-direction: column;
    margin: 0;
  }
}
.page-wrapper .more-info-visible .info-text .developped-skills img {
  height: 8vw;
}
@media (max-width: 1000px) {
  .page-wrapper .more-info-visible .info-text .developped-skills img {
    height: 6vw;
  }
}
@media (max-width: 600px) {
  .page-wrapper .more-info-visible .info-text .developped-skills img {
    height: 10vw;
  }
}
.page-wrapper .more-info-visible .info-text .developped-skills .skill-icon {
  display: flex;
  gap: 50px;
  font-weight: 700;
}
@media (max-width: 1000px) {
  .page-wrapper .more-info-visible .info-text .developped-skills .skill-icon {
    gap: 20px;
  }
}/*# sourceMappingURL=Project.css.map */