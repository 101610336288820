.container {
  background-color: rgb(33, 33, 33);
}
.container .home-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.container .home-container .wrapper {
  display: flex;
  align-items: center;
  width: 100vw;
  height: 100vh;
}
.container .home-container .wrapper .intro {
  margin-left: 50px;
  color: rgb(200, 200, 200);
  display: inline-block;
}
.container .home-container .wrapper .intro h1 {
  margin: 0;
  font-size: 72px;
}
.container .home-container .wrapper .intro .intro-text {
  width: -moz-fit-content;
  width: fit-content;
}
.container .home-container .wrapper .intro .intro-span {
  font-size: 48px;
}
.container .home-container .wrapper .links {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  background-color: rgb(33, 33, 33);
}
.container .home-container .wrapper .links .link {
  text-decoration: none;
  min-height: 50px;
  min-width: 5vw;
  color: rgb(200, 200, 200);
  font-size: 24px;
  transition: 0.5s;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  padding: 0 10px;
  flex-wrap: nowrap;
}
.container .home-container .wrapper .links .link:hover {
  box-shadow: 0px 8px 20px black;
  transform: translateY(20%);
}
.container .home-container .skills-wrapper {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.container .home-container .skills-wrapper .skills-title {
  display: inline-flex;
  order: 1;
  flex-direction: column;
  align-items: center;
  font-size: 38px;
  font-weight: 700;
  background: -webkit-linear-gradient(rgb(226, 226, 226), #383838);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  gap: -20px;
}
.container .home-container .skills-wrapper .skills-block {
  width: 90%;
  display: flex;
  justify-content: center;
}
.container .home-container .skills-wrapper .skills-block .skills-container {
  display: grid;
  justify-content: center;
  width: 25%;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 0px;
  -moz-column-gap: 50px;
       column-gap: 50px;
  /* .always-open-logo .skill-description{
      opacity: 1;
      visibility: visible;
      margin-bottom: 2em;
  } */
}
.container .home-container .skills-wrapper .skills-block .skills-container .skill img {
  height: 6vw;
  transition: 0.5s;
}
@media (max-width: 1400px) {
  .container .home-container .skills-wrapper .skills-block .skills-container .skill img {
    height: 10vw;
  }
}
@media (max-width: 1000px) {
  .container .home-container .skills-wrapper .skills-block .skills-container .skill img {
    height: 12vw;
  }
}
.container .home-container .skills-wrapper .skills-block .skills-container .skill h3 {
  color: white;
  text-align: center;
}
.container .home-container .skills-wrapper .skills-block .skills-container .skill-description {
  opacity: 0;
  visibility: hidden;
  width: 100%;
  transition-duration: 0.3s;
}
@media (max-width: 1400px) {
  .container .home-container .skills-wrapper .skills-block .skills-container .skill-description {
    opacity: 1;
    visibility: visible;
    margin-bottom: 2em;
  }
}
.container .home-container .skills-wrapper .skills-block .skills-container .skill {
  transition: 0.5s;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.container .home-container .skills-wrapper .skills-block .skills-container .open-logo {
  transform: translateY(-20%);
}
.container .home-container .skills-wrapper .skills-block .skills-container .open-logo img {
  transform: rotateY(180deg);
}
.container .home-container .skills-wrapper .skills-block .skills-container .open-logo .skill-description {
  opacity: 1;
  visibility: visible;
}
.container .home-container .contact-wrapper {
  height: 100vh;
  width: 100%;
  background: rgb(33, 33, 33);
  background: linear-gradient(315deg, rgb(33, 33, 33) 0%, rgb(71, 71, 71) 30%, rgb(124, 124, 124) 60%, rgb(255, 255, 255) 100%);
}
.container .home-container .scroll-to-top-button {
  transition: 0.5s;
  position: fixed;
  right: 3%;
  bottom: 10%;
  z-index: 50;
  height: 70px;
  opacity: 0;
  border-radius: 50%;
}
.container .home-container .scroll-to-top-button.visible {
  opacity: 1;
  transition: 0.5s;
}
.container .home-container .scroll-to-top-button:hover {
  transform: scale(1.2);
}/*# sourceMappingURL=Home.css.map */