.contact{
    transition: .5s;
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    
    .contact-title{
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        font-size: 38px;
        font-weight: 700;
        background: -webkit-linear-gradient(rgb(33,33,33), #9e9e9e);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        gap: -20px;
    }

    form{
        width: 80%;
        height: 100%;
        display: flex;
        justify-content: center;
        @media (max-width: 1000px){
            flex-direction: column;
            align-items: center;
            gap: 2em;
        }
        *{
            cursor: none;
            position: relative;
            text-align: center;
            border-radius: 50%;
            height: 15vw;
            width: 15vw;
            background-color: rgb(33, 33, 33);
            color: white;
            outline: none;
            font-weight: 700;
            border: none;
            box-shadow: 0px 8px 20px black;
            transition: .5s;

            @media (max-width: 1400px){
                height: 20vw;
                width: 20vw;
            }

            @media (max-width: 1000px){
                border-radius: 10px;
                animation: none;
                height: 10%;
                width: 50%;
                position: static;
            }
        }
        *:hover{
            box-shadow: 8px 8px 30px black;
        }

        #email{
            top: 5%;
            animation: float 5s infinite alternate;
            @media (max-width: 1000px){
                animation: none;
            }
        }
        #name{
            top: 20%;
            left: 10%;
            animation: floattwo 10s infinite alternate;
            @media (max-width: 1000px){
                animation: none;
            }
        }
        #message{
            top: 60%;
            right: 40%;
            resize: none;
            transition: .5s;
            animation: floatthree 10s infinite alternate;
            @media (max-width: 1000px){
                animation: none;
                height: 20%;
            }
        }
        #message:focus{
            border-radius: 50px;
            
            @media (max-width: 1000px){
                border-radius: 10px;
            }
        }

        *::placeholder, button{
            font-size: 16px;
            font-weight: 700;
            align-self: center;
            color: white;
        }
        button{
            top: 20%;
            left: 15%;
            background-color: rgb(33, 33, 33);
            animation: floatthree 10s infinite alternate;
            @media (max-width: 1000px){
                animation: none;
                order: 3;
            }
        }
        button:hover{
            background-color: rgb(62, 62, 62);
            color: rgb(21, 21, 21);
        }
    }

    .dot{
        height: 50px;
        width: 50px;
        position: relative;
        z-index: 50;
        border-radius: 50%;
        background-color: rgb(33, 33, 33);

        @media (max-width: 1400px){
            display: none;
        }

    }
    .dot-one{
        height: 30px;
        width: 30px;
        bottom: 20%;
        left: 70%;
        animation: floattwo 5s infinite alternate;

    }
    .dot-two{
        height: 42px;
        width: 42px;
        bottom: -20%;
        left: 15%;
        animation: float 5s infinite alternate;

    }
    .dot-three{
        height: 25px;
        width: 25px;
        bottom: 35%;
        left: 60%;
        animation: floatthree 5s infinite alternate;

    }
    .dot-four{
        height: 38px;
        width: 38px;
        bottom: 20%;
        left: 80%;
        animation: float 5s infinite alternate;

    }
    
    .dot-five{
        bottom: 10%;
        left: 15%;
        animation: floattwo 5s infinite alternate;

    }

}


@keyframes float{
0% {transform: translate(10%, 8%);}
50% {transform: translate(8%, -5%);}
100% {transform: translate(5%, 5%);}
}
@keyframes floattwo{
0% {transform: translate(10%, 8%);}
50% {transform: translate(8%, 5%);}
100% {transform: translate(0, -5%);}
}
@keyframes floatthree{
0% {transform: translate(10%, 8%);}
50% {transform: translate(15%, -5%);}
100% {transform: translate(-5%, 5%);}
}