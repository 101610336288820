.page-wrapper{
    background: rgb(33,33,33);
    background: linear-gradient(45deg, rgba(33,33,33,1) 0%, rgba(71,71,71,1) 30%, rgba(124,124,124,1) 60%, rgba(255,255,255,1) 100%); 
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100vw;
    min-height: 100vh;

    .projects-main{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        .projects-title{
            display: inline-flex;
            flex-direction: column;
            align-items: center;
            font-size: 38px;
            font-weight: 700;
            background: -webkit-linear-gradient(rgb(226, 226, 226), #383838);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            gap: -20px;
        }
    
        .projects-block{
            width: 90%;
            display: flex;
            justify-content: center;
    
            .projects-wrapper {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                width: 80%;
                gap: 50px;
                justify-content: center;
                transition: .5s;
                opacity: 1;
                margin: 10em 0;
        
                @media (max-width: 1400px){
                        display: flex;
                        flex-direction: column;
                }
        
                .project-card {
                    position: relative;
                    text-align: center;
                    overflow: hidden;
                    border-radius: 20px;
                    box-shadow: -8px 10px 20px rgb(47, 47, 47);
                    transition: .5s;
        
                    img {
                        object-fit: cover;
                        width: 100%;
                        height: 100%;
                        transition: .5s;
                    }
                }
        
                .project-card:hover {
                    transform: scale(1.1);
                    img{
                        transform: scale(1.1);
                    }
                }
                .project-card:hover .project-info {
                    opacity: 1;
                }
                
                .project-info {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 100%;
                    background-color: rgba(0, 0, 0, 0.7);
                    color: white;
                    border-radius: 10px;
                    opacity: 0;
                    transition: opacity 0.3s ease;

                    @media (max-width: 1400px){
                        opacity: 1;
                    }
        
                    p{
                        font-size: 1vw;
                        margin: 20px 0;

                        @media (max-width: 1400px){
                            font-size: 3vw;
                        }
                        @media (max-width: 600px){
                            font-size: 4vw;
                        }
                        
                    }
                    h1{
                        margin: 20px 0;

                        @media (max-width: 1400px){
                            font-size: 4vw;
                        }
                        @media (max-width: 600px){
                            font-size: 5vw;
                        }
                    }
                
                    .more-info{
                        color: white;
                        text-decoration: underline;
                    }
                }    
            }
        }

    }

    
    

    .more-info-hidden {
        transition: .5s;
        opacity: 0;
        transform: scale(0);
    }
    
    .more-info-visible{
        transform: scale(1);
        opacity: 1; 
        border-radius: 20px;
        background-color: rgb(33, 33, 33);
        width: 80%;
        transition: .5s;
        padding: 20px;
        margin-bottom: 10em;
        color: white;
        box-shadow: -10px 10px 40px rgb(0, 0, 0), 5px -5px 80px rgba(97, 97, 97, 0.469), inset -2px 2px 20px rgba(97, 97, 97, 0.469);

        .content{
            width: 100%;
            display: flex;
            justify-content: center;
        }

        .info-text{
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            gap: 25px;
            width: 100%;

            .link {
                text-decoration: none;
                min-height: 50px;
                min-width: 2vw;
                color: white;
                font-size: 1em;
                transition: .5s;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                border-radius: 10px;
                padding: 0 10px;
            }
            /* .see-site{
                position: relative;
                bottom: 0;
            } */

            .link:hover {
                box-shadow: 0px 8px 20px black;
                transform: translateY(10%);
            }

            .info-grid{
                display: flex;
                justify-content: center;
                gap: 25px;
                width: 100%;
                @media (max-width: 600px){
                    flex-direction: column;
                    align-items: center;
                }
            }

            .info-grid > div{
                box-shadow: -4px 4px 10px black;
                border-radius: 10px;
                transition: .5s;
                overflow: hidden;
                background-color: rgb(33, 33, 33);
                width: 30%;
                padding: 0 5%;
                @media (max-width: 600px){
                    width: 50%;
                }
            }
        
            .more-info, .link, .see-site{
                color: white;
                font-weight: 700;
                align-self: flex-start;
                transition: .5s;
            }
            a:hover{
                color: grey;
            }
        
            h3{
                align-self: center;
                font-size: 26px;

                @media (max-width: 1000px){
                    font-size: 2.5vw;
                }
            }
        
            p{
                font-size: 20px;
                @media (max-width: 1000px){
                    font-size: 2vw;
                }
                @media (max-width: 600px){
                    font-size: 3vw;
                }
            }

            .developped-skills{
                display: flex;
                flex-direction: row;
                margin-top: 50px;
                gap: 50px;
                @media (max-width: 600px){
                    flex-direction: column;
                    margin: 0;
                }
                
                img{
                    height: 8vw;
                    @media (max-width: 1000px){
                        height: 6vw;
                    }
                    @media (max-width: 600px){
                        height: 10vw;
                    }
                }

                .skill-icon{
                    display: flex;
                    gap: 50px;
                    font-weight: 700;
                    @media (max-width: 1000px){
                        gap: 20px;
                    }
                }
            }
        }
    }
}


