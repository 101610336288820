.contact {
  transition: 0.5s;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.contact .contact-title {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  font-size: 38px;
  font-weight: 700;
  background: -webkit-linear-gradient(rgb(33, 33, 33), #9e9e9e);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  gap: -20px;
}
.contact form {
  width: 80%;
  height: 100%;
  display: flex;
  justify-content: center;
}
@media (max-width: 1000px) {
  .contact form {
    flex-direction: column;
    align-items: center;
    gap: 2em;
  }
}
.contact form * {
  cursor: none;
  position: relative;
  text-align: center;
  border-radius: 50%;
  height: 15vw;
  width: 15vw;
  background-color: rgb(33, 33, 33);
  color: white;
  outline: none;
  font-weight: 700;
  border: none;
  box-shadow: 0px 8px 20px black;
  transition: 0.5s;
}
@media (max-width: 1400px) {
  .contact form * {
    height: 20vw;
    width: 20vw;
  }
}
@media (max-width: 1000px) {
  .contact form * {
    border-radius: 10px;
    animation: none;
    height: 10%;
    width: 50%;
    position: static;
  }
}
.contact form *:hover {
  box-shadow: 8px 8px 30px black;
}
.contact form #email {
  top: 5%;
  animation: float 5s infinite alternate;
}
@media (max-width: 1000px) {
  .contact form #email {
    animation: none;
  }
}
.contact form #name {
  top: 20%;
  left: 10%;
  animation: floattwo 10s infinite alternate;
}
@media (max-width: 1000px) {
  .contact form #name {
    animation: none;
  }
}
.contact form #message {
  top: 60%;
  right: 40%;
  resize: none;
  transition: 0.5s;
  animation: floatthree 10s infinite alternate;
}
@media (max-width: 1000px) {
  .contact form #message {
    animation: none;
    height: 20%;
  }
}
.contact form #message:focus {
  border-radius: 50px;
}
@media (max-width: 1000px) {
  .contact form #message:focus {
    border-radius: 10px;
  }
}
.contact form *::-moz-placeholder {
  font-size: 16px;
  font-weight: 700;
  align-self: center;
  color: white;
}
.contact form *::placeholder, .contact form button {
  font-size: 16px;
  font-weight: 700;
  align-self: center;
  color: white;
}
.contact form button {
  top: 20%;
  left: 15%;
  background-color: rgb(33, 33, 33);
  animation: floatthree 10s infinite alternate;
}
@media (max-width: 1000px) {
  .contact form button {
    animation: none;
    order: 3;
  }
}
.contact form button:hover {
  background-color: rgb(62, 62, 62);
  color: rgb(21, 21, 21);
}
.contact .dot {
  height: 50px;
  width: 50px;
  position: relative;
  z-index: 50;
  border-radius: 50%;
  background-color: rgb(33, 33, 33);
}
@media (max-width: 1400px) {
  .contact .dot {
    display: none;
  }
}
.contact .dot-one {
  height: 30px;
  width: 30px;
  bottom: 20%;
  left: 70%;
  animation: floattwo 5s infinite alternate;
}
.contact .dot-two {
  height: 42px;
  width: 42px;
  bottom: -20%;
  left: 15%;
  animation: float 5s infinite alternate;
}
.contact .dot-three {
  height: 25px;
  width: 25px;
  bottom: 35%;
  left: 60%;
  animation: floatthree 5s infinite alternate;
}
.contact .dot-four {
  height: 38px;
  width: 38px;
  bottom: 20%;
  left: 80%;
  animation: float 5s infinite alternate;
}
.contact .dot-five {
  bottom: 10%;
  left: 15%;
  animation: floattwo 5s infinite alternate;
}

@keyframes float {
  0% {
    transform: translate(10%, 8%);
  }
  50% {
    transform: translate(8%, -5%);
  }
  100% {
    transform: translate(5%, 5%);
  }
}
@keyframes floattwo {
  0% {
    transform: translate(10%, 8%);
  }
  50% {
    transform: translate(8%, 5%);
  }
  100% {
    transform: translate(0, -5%);
  }
}
@keyframes floatthree {
  0% {
    transform: translate(10%, 8%);
  }
  50% {
    transform: translate(15%, -5%);
  }
  100% {
    transform: translate(-5%, 5%);
  }
}/*# sourceMappingURL=Contact.css.map */