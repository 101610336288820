.container {
    background-color: rgb(33, 33, 33);

    .home-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .wrapper {
            display: flex;
            align-items: center;
            width: 100vw;
            height: 100vh;

            .intro {
                margin-left: 50px;
                color: rgb(200, 200, 200);
                display: inline-block;

                h1 {
                    margin: 0;
                    font-size: 72px;
                }

                .intro-text {
                    width: fit-content;
                }

                .intro-span {
                    font-size: 48px;
                }
            }

            .links {
                display: flex;
                justify-content: space-between;
                margin-top: 20px;
                background-color: rgb(33, 33, 33);


                .link {
                    text-decoration: none;
                    min-height: 50px;
                    min-width: 5vw;
                    color: rgb(200, 200, 200);
                    font-size: 24px;
                    transition: .5s;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 10px;
                    padding: 0 10px;
                    flex-wrap: nowrap;

                }

                .link:hover {
                    box-shadow: 0px 8px 20px black;
                    transform: translateY(20%);
                }
            }
        }

        .skills-wrapper{
            height: 100vh;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            .skills-title{
                display: inline-flex;
                order: 1;
                
                flex-direction: column;
                align-items: center;
                font-size: 38px;
                font-weight: 700;
                background: -webkit-linear-gradient(rgb(226, 226, 226), #383838);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                gap: -20px;
            }
    
            .skills-block{
                width: 90%;
                display: flex;
                justify-content: center;
        
                .skills-container {
                    display: grid;
                    justify-content: center;
                    width: 25%;
                    grid-template-columns: repeat(3, 1fr);
                    row-gap: 0px;
                    column-gap: 50px;
        
                    .skill img {
                        height: 6vw;
                        transition: .5s;
                    }
                    @media (max-width: 1400px) { 
                        .skill img{
                            height: 10vw;
                        }
                    }
                    @media (max-width: 1000px) { 
                        .skill img{
                            height: 12vw;
                        }
                    }
        
                    .skill h3 {
                        color: white;
                        text-align: center;
                    }
        
                    .skill-description {
                        opacity: 0;
                        visibility: hidden; // Masque l'élément sans le retirer du flux du document
                        width: 100%;
                        transition-duration: .3s;

                        @media (max-width: 1400px){
                            opacity: 1;
                            visibility: visible;
                            margin-bottom: 2em;
                        }
                    }
        
                    .skill {
                        transition: .5s;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                    }

                    /* .always-open-logo .skill-description{
                        opacity: 1;
                        visibility: visible;
                        margin-bottom: 2em;
                    } */
        
                    .open-logo {
                        transform: translateY(-20%);
                    }
        
                    .open-logo img {
                        transform: rotateY(180deg);
                    }
        
                    .open-logo .skill-description {
                        opacity: 1;
                        visibility: visible; // Rend l'élément visible lorsque la classe .open-logo est présente
                    }
        
                }
            }
        }
        .contact-wrapper{
            height: 100vh;
            width: 100%;
            background: rgb(33,33,33);
            background: linear-gradient(315deg, rgba(33,33,33,1) 0%, rgba(71,71,71,1) 30%, rgba(124,124,124,1) 60%, rgba(255,255,255,1) 100%); 
        }

        .scroll-to-top-button {
            transition: .5s;
            position: fixed;
            right: 3%;
            bottom: 10%;
            z-index: 50;
            height: 70px;
            opacity: 0;
            border-radius: 50%;
        }

        .scroll-to-top-button.visible {
            opacity: 1;
            transition: .5s;
        }

        .scroll-to-top-button:hover {
            transform: scale(1.2);
        }
    }
}

